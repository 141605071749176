var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-recommendations-page"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-tasks"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function fn() {
        return [_vm._v("A set of recommendations that will help reduce your carbon footprint, it is also possible to add your own.")];
      },
      proxy: true
    }])
  }, [[_vm._v(" Goals & Recommendations ")]], 2), _c('div', {
    staticClass: "row items-push mb-0"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.search,
      expression: "filters.search"
    }],
    staticClass: "form-control border-left-0",
    attrs: {
      "id": "recommendations-search",
      "type": "text",
      "name": "recommendations-search",
      "placeholder": "Search recommendations.."
    },
    domProps: {
      "value": _vm.filters.search
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.filters, "search", $event.target.value);
      }, _vm.onSearch]
    }
  })])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.status,
      expression: "filters.status"
    }],
    staticClass: "custom-select",
    attrs: {
      "id": "recommendations-filter",
      "name": "recommendations-filter"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.filters, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "selected": "",
      "value": ""
    }
  }, [_vm._v("All (" + _vm._s(_vm.allRecommendations.length) + ")")]), _c('option', {
    attrs: {
      "value": "pending"
    }
  }, [_vm._v("Pending (" + _vm._s(_vm.pendingRecommendations) + ")")]), _c('option', {
    attrs: {
      "value": "completed"
    }
  }, [_vm._v("Completed (" + _vm._s(_vm.completedRecommendations) + ")")])])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.category,
      expression: "filters.category"
    }],
    staticClass: "custom-select",
    attrs: {
      "id": "recommendations-filter-category",
      "name": "recommendations-filter"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.filters, "category", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "selected": "",
      "value": ""
    }
  }, [_vm._v("All Categories (" + _vm._s(_vm.allRecommendations.length) + ")")]), _vm._l(_vm.categories, function (cat) {
    return _c('option', {
      key: cat.value,
      domProps: {
        "value": cat.value
      }
    }, [_vm._v(_vm._s(cat.label) + " (" + _vm._s(_vm.getCategoryCount(cat.value)) + ")")]);
  })], 2)]), _c('div', {
    staticClass: "col text-right d-flex justify-content-end"
  }, [_vm.$auth.isAdmin ? _c('button', {
    staticClass: "btn btn-primary mr-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onRecommendationModal(true, 'modify', null);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" GLOBAL ")]) : _vm._e(), _vm.$permissions.write('asset', _vm.asset) ? _c('button', {
    staticClass: "btn btn-primary mr-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onRecommendationModal(false, 'modify', null);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" NEW ")]) : _vm._e()])]), _vm.loading ? _c('div', {
    staticClass: "d-flex justify-content-center py-5"
  }, [_vm._m(1)]) : _vm._l(_vm.categories, function (cat) {
    return _c('div', {
      key: cat.value
    }, [_vm.filters.category === cat.value || _vm.filters.category === '' ? _c('div', {
      staticClass: "pb-4"
    }, [_c('SectionTitle', [_vm._v(_vm._s(cat.label))]), _vm._l(_vm.recommendations.filter(function (r) {
      return r.category === cat.value;
    }).sort(function (a, b) {
      return a.name > b.name ? 1 : -1;
    }), function (rec) {
      return _c('div', {
        key: rec._id
      }, [_c('div', {
        staticClass: "row"
      }, [_c('div', {
        staticClass: "col-md-auto d-flex justify-content-between align-items-center"
      }, [_c('i', {
        class: "fa-fw fa-duotone text-primary fa-lg fa-".concat(rec.icon ? rec.icon : 'user-check')
      })]), _c('div', {
        staticClass: "col-lg align-items-center p-3"
      }, [_c('div', [_c('strong', [_vm._v(_vm._s(rec.name) + " "), rec.completed ? _c('i', {
        staticClass: "fa text-success fa-check ml-1"
      }) : _vm._e()])]), _c('div', [_c('small', {
        staticClass: "text-muted"
      }, [_vm._v(_vm._s(rec.description) + " ")])])]), _c('div', {
        staticClass: "d-flex align-items-center"
      }, [_c('button', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: false,
          expression: "false"
        }],
        staticClass: "btn btn-link details-button font-w600",
        attrs: {
          "type": "button"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.onClickDocumentUpload();
          }
        }
      }, [_c('i', {
        staticClass: "fa-fw fa-duotone fa-file mr-1"
      }), _vm._v(" "), _c('span', [_vm._v("Upload")])]), _vm.$permissions.write('asset', _vm.asset) ? _c('button', {
        class: "btn btn-link text-".concat(rec.completed ? 'danger' : 'primary', " details-button  font-w600"),
        attrs: {
          "type": "button"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.updateRecommendationModal(rec, rec.completed ? false : true);
          }
        }
      }, [_c('i', {
        class: "fa-fw fa-duotone fa-".concat(rec.completed ? 'xmark' : 'check', "  mr-1")
      }), _c('span', [_vm._v(" " + _vm._s(rec.completed ? 'Unconfirm' : 'Confirm') + " ")])]) : _vm._e(), _vm.$auth.isAdmin && !rec.companyId ? _c('button', {
        staticClass: "btn details-button btn-link text-warning font-w600",
        attrs: {
          "type": "button"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.onRecommendationModal(true, 'modify', rec);
          }
        }
      }, [_c('i', {
        staticClass: "fa-fw fa-duotone fa-pencil mr-1"
      }), _vm._v(" "), _c('span', [_vm._v("Edit")])]) : _vm._e(), rec.companyId && rec.assetId && (_vm.$permissions.write('asset', _vm.asset) || _vm.$auth.roles.includes('entity_owner')) ? _c('button', {
        staticClass: "btn details-button btn-link text-warning font-w600",
        attrs: {
          "type": "button"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.onRecommendationModal(false, 'modify', rec);
          }
        }
      }, [_c('i', {
        staticClass: "fa-fw fa-duotone fa-pencil mr-1"
      }), _vm._v(" "), _c('span', [_vm._v("Edit")])]) : _vm._e()])])]);
    })], 2) : _vm._e()]);
  }), _c('Modal', {
    attrs: {
      "id": "modifyRecommend",
      "open": !!_vm.modals.modify,
      "title": "".concat(_vm.form._id !== '' ? 'Modifying Recommendation' : 'Creating Recommendation')
    },
    on: {
      "close": function close($event) {
        return _vm.onModalClose('modify');
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [!_vm.$auth.isAdmin && _vm.form.companyId !== null && _vm.form._id ? _c('button', {
          staticClass: "btn btn-danger mr-5",
          on: {
            "click": function click($event) {
              return _vm.onDeleteRecommendation();
            }
          }
        }, [_vm._v(" Delete ")]) : _vm._e(), _vm.$auth.isAdmin && _vm.form._id ? _c('button', {
          staticClass: "btn btn-danger mr-5",
          on: {
            "click": function click($event) {
              return _vm.onDeleteRecommendation();
            }
          }
        }, [_vm._v("Delete")]) : _vm._e(), _c('button', {
          staticClass: "btn btn-primary",
          on: {
            "click": function click($event) {
              return _vm.onClickRecommendation('modify');
            }
          }
        }, [_vm._v("Confirm")])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_vm.$auth.isAdmin && _vm.globalFlag ? _c('i', [_vm._v("(Etainabl admin only - "), _vm.globalFlag ? _c('strong', {
    staticClass: "text-danger"
  }, [_vm._v("GLOBAL")]) : _vm._e(), _vm._v(")")]) : _vm._e(), _vm.globalFlag ? _c('p', [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v("WARNING:")]), _vm._v(" This is creating a global recommendation that will appear on every site ")]) : _vm._e(), _vm.$auth.isAdmin ? _c('div', [_vm._v("Preview Icon: "), _c('i', {
    class: "fa-duotone fa-".concat(_vm.form.icon)
  }), _vm._v(" "), _c('small', [_vm._v(" (admin only)")])]) : _vm._e(), _vm.$auth.isAdmin ? _c('FormGroup', {
    attrs: {
      "id": "icon",
      "label": "Icon",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.icon,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "icon", $$v);
      },
      expression: "form.icon"
    }
  }) : _vm._e(), _c('FormGroup', {
    attrs: {
      "id": "category",
      "error": _vm.validationErrors.category,
      "label": "Category",
      "type": "select",
      "placeholder": "Select a category",
      "value": _vm.form.category,
      "options": _vm.categories
    },
    model: {
      value: _vm.form.category,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "category", $$v);
      },
      expression: "form.category"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "name",
      "error": _vm.validationErrors.name,
      "label": "Name",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "description",
      "error": _vm.validationErrors.description,
      "label": "Description",
      "type": "textarea"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)])]), _c('Modal', {
    attrs: {
      "id": "updateStatus",
      "open": !!_vm.modals.statusUpdate,
      "title": "".concat(_vm.singleRecommendation.completed ? 'Changing completed recommendation to in-progress' : 'Marking recommendation as completed')
    },
    on: {
      "ok": _vm.onClickUpdateRecommendationStatus,
      "close": function close($event) {
        _vm.modals.statusUpdate = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('button', {
          staticClass: "btn btn-primary",
          on: {
            "click": function click($event) {
              return _vm.onClickUpdateRecommendationStatus(_vm.singleRecommendation.completed);
            }
          }
        }, [_vm._v("Confirm")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_vm.singleRecommendation.completed ? _c('div', [_c('p', [_vm._v("Are you sure you want to change this recommendation to in-progress?")]), _c('i', {
    staticClass: "fa-duotone fa-xmark"
  }), _c('small', [_vm._v(" " + _vm._s(_vm.singleRecommendation.name) + " ")])]) : _c('div', [_c('p', [_vm._v("Are you sure you want to mark this recommendation as completed?")]), _c('i', {
    staticClass: "fa-duotone fa-check"
  }), _c('small', [_vm._v(" " + _vm._s(_vm.singleRecommendation.name) + " ")])])])])])], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text bg-white"
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner-border spinner-lg text-secondary my-5",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]);
}]

export { render, staticRenderFns }