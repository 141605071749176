<template>
  <div v-show="open" ref="modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="{ 'modal-dialog-centered': centered }">
      <div class="modal-content">
        <div class="modal-header bg-light py-3">
          <slot name="header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="btn btn-ghost-light" @click="onClose">
              <i class="fa fa-times" />
            </button>
          </slot>
        </div>
        <div class="modal-body" :class="{ 'text-center': centered }">
          <slot></slot>
        </div>
        <div class="modal-footer p-3" :class="{ 'justify-content-center': centered }">
          <slot name="footer">
            <button type="button" class="btn btn-soft-light" @click="onClose">
              {{ closeText }}
            </button>
            <button type="button" class="btn btn-primary" @click="onSubmit">
              {{ submitText }}
            </button>
          </slot>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
export default {
  props: {
    open: {
      type: [Boolean, Object],
      default: false,
      required: false
    },
    title: {
      type: String,
      default: 'Confirm'
    },
    text: {
      type: String,
      default: 'Are you sure?'
    },
    id: {
      type: String,
      default: ''
    },
    centered: {
      type: Boolean,
      default: false
    },
    closeText: {
      type: String,
      default: 'Close'
    },
    submitText: {
      type: String,
      default: 'Save'
    }
  },
  watch: {
    open(isShow) {
      jQuery(this.$refs.modal).modal(isShow ? 'show' : 'hide');
    }
  },
  beforeDestroy() {
    jQuery(this.$refs.modal).modal('hide');
  },
  mounted() {
    jQuery(this.$refs.modal).on('hidden.bs.modal', () => {
      this.$emit('close');
    });
  },
  methods: {
    onSubmit() {
      this.$emit('submit');
      if (!this.prevent) this.$emit('close');
    },
    onClose() {
      this.$emit('close');
    }
  }
};
</script>
