var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open,
      expression: "open"
    }],
    ref: "modal",
    staticClass: "modal fade",
    attrs: {
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "modal-dialog",
    class: {
      'modal-dialog-centered': _vm.centered
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-header bg-light py-3"
  }, [_vm._t("header", [_c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('button', {
    staticClass: "btn btn-ghost-light",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onClose
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  })])])], 2), _c('div', {
    staticClass: "modal-body",
    class: {
      'text-center': _vm.centered
    }
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "modal-footer p-3",
    class: {
      'justify-content-center': _vm.centered
    }
  }, [_vm._t("footer", [_c('button', {
    staticClass: "btn btn-soft-light",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v(" " + _vm._s(_vm.closeText) + " ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.submitText) + " ")])])], 2)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }