<template>
  <div data-cy="asset-recommendations-page">
    <TabTitle class="mb-4" icon="fa-tasks">
      <template> Goals &amp; Recommendations </template>
      <template #sub-title>A set of recommendations that will help reduce your carbon footprint, it is also possible to add your own.</template>
    </TabTitle>

    <div class="row items-push mb-0">
      <div class="col-md-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text bg-white">
              <i class="fa fa-search"></i>
            </span>
          </div>
          <input
            id="recommendations-search"
            v-model="filters.search"
            type="text"
            class="form-control border-left-0"
            name="recommendations-search"
            placeholder="Search recommendations.."
            @input="onSearch"
          />
        </div>
      </div>
      <div class="col-md-3">
        <select id="recommendations-filter" v-model="filters.status" class="custom-select" name="recommendations-filter">
          <option selected value="">All ({{ allRecommendations.length }})</option>
          <option value="pending">Pending ({{ pendingRecommendations }})</option>
          <option value="completed">Completed ({{ completedRecommendations }})</option>
        </select>
      </div>
      <div class="col-md-3">
        <select id="recommendations-filter-category" v-model="filters.category" class="custom-select" name="recommendations-filter">
          <option selected value="">All Categories ({{ allRecommendations.length }})</option>
          <option v-for="cat in categories" :key="cat.value" :value="cat.value">{{ cat.label }} ({{ getCategoryCount(cat.value) }})</option>
        </select>
      </div>
      <div class="col text-right d-flex justify-content-end">
        <button v-if="$auth.isAdmin" type="button" class="btn btn-primary mr-3" @click.prevent="onRecommendationModal(true, 'modify', null)">
          <i class="fa fa-plus"></i> GLOBAL
        </button>

        <button
          v-if="$permissions.write('asset', asset)"
          type="button"
          class="btn btn-primary mr-3"
          @click.prevent="onRecommendationModal(false, 'modify', null)"
        >
          <i class="fa fa-plus"></i> NEW
        </button>
      </div>
    </div>

    <div v-if="loading" class="d-flex justify-content-center py-5">
      <div class="spinner-border spinner-lg text-secondary my-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-for="cat in categories" v-else :key="cat.value">
      <div v-if="filters.category === cat.value || filters.category === ''" class="pb-4">
        <SectionTitle>{{ cat.label }}</SectionTitle>
        <div v-for="rec in recommendations.filter(r => r.category === cat.value).sort((a, b) => (a.name > b.name ? 1 : -1))" :key="rec._id">
          <div class="row">
            <div class="col-md-auto d-flex justify-content-between align-items-center">
              <i :class="`fa-fw fa-duotone text-primary fa-lg fa-${rec.icon ? rec.icon : 'user-check'}`"></i>
            </div>
            <div class="col-lg align-items-center p-3">
              <div>
                <strong>{{ rec.name }} <i v-if="rec.completed" class="fa text-success fa-check ml-1"></i></strong>
              </div>
              <div>
                <small class="text-muted">{{ rec.description }} </small>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <button v-show="false" type="button" class="btn btn-link details-button font-w600" @click.prevent="onClickDocumentUpload()">
                <i class="fa-fw fa-duotone fa-file mr-1"></i> <span>Upload</span>
              </button>

              <button
                v-if="$permissions.write('asset', asset)"
                type="button"
                :class="`btn btn-link text-${rec.completed ? 'danger' : 'primary'} details-button  font-w600`"
                @click.prevent="updateRecommendationModal(rec, rec.completed ? false : true)"
              >
                <i :class="`fa-fw fa-duotone fa-${rec.completed ? 'xmark' : 'check'}  mr-1`"> </i>
                <span> {{ rec.completed ? 'Unconfirm' : 'Confirm' }} </span>
              </button>
              <button
                v-if="$auth.isAdmin && !rec.companyId"
                type="button"
                class="btn details-button btn-link text-warning font-w600"
                @click.prevent="onRecommendationModal(true, 'modify', rec)"
              >
                <i class="fa-fw fa-duotone fa-pencil mr-1"></i> <span>Edit</span>
              </button>
              <button
                v-if="rec.companyId && rec.assetId && ($permissions.write('asset', asset) || $auth.roles.includes('entity_owner'))"
                type="button"
                class="btn details-button btn-link text-warning font-w600"
                @click.prevent="onRecommendationModal(false, 'modify', rec)"
              >
                <i class="fa-fw fa-duotone fa-pencil mr-1"></i> <span>Edit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      id="modifyRecommend"
      :open="!!modals.modify"
      :title="`${form._id !== '' ? 'Modifying Recommendation' : 'Creating Recommendation'}`"
      @close="onModalClose('modify')"
    >
      <div class="row">
        <div class="col">
          <i v-if="$auth.isAdmin && globalFlag">(Etainabl admin only - <strong v-if="globalFlag" class="text-danger">GLOBAL</strong>)</i>
          <p v-if="globalFlag">
            <strong class="text-danger">WARNING:</strong> This is creating a global recommendation that will appear on every site
          </p>
          <div v-if="$auth.isAdmin">Preview Icon: <i :class="`fa-duotone fa-${form.icon}`" /> <small> (admin only)</small></div>
          <FormGroup v-if="$auth.isAdmin" id="icon" v-model="form.icon" label="Icon" type="text" @input="$emit('input', form)" />
          <FormGroup
            id="category"
            v-model="form.category"
            :error="validationErrors.category"
            label="Category"
            type="select"
            placeholder="Select a category"
            :value="form.category"
            :options="categories"
          />
          <FormGroup id="name" v-model="form.name" :error="validationErrors.name" label="Name" type="text" @input="$emit('input', form)" />
          <FormGroup
            id="description"
            v-model="form.description"
            :error="validationErrors.description"
            label="Description"
            type="textarea"
            @input="$emit('input', form)"
          />
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between">
          <button v-if="!$auth.isAdmin && form.companyId !== null && form._id" class="btn btn-danger mr-5" @click="onDeleteRecommendation()">
            Delete
          </button>
          <button v-if="$auth.isAdmin && form._id" class="btn btn-danger mr-5" @click="onDeleteRecommendation()">Delete</button>
          <button class="btn btn-primary" @click="onClickRecommendation('modify')">Confirm</button>
        </div>
      </template>
    </Modal>

    <Modal
      id="updateStatus"
      :open="!!modals.statusUpdate"
      :title="`${singleRecommendation.completed ? 'Changing completed recommendation to in-progress' : 'Marking recommendation as completed'}`"
      @ok="onClickUpdateRecommendationStatus"
      @close="modals.statusUpdate = false"
    >
      <div class="row">
        <div class="col">
          <div v-if="singleRecommendation.completed">
            <p>Are you sure you want to change this recommendation to in-progress?</p>

            <i class="fa-duotone fa-xmark"></i><small> {{ singleRecommendation.name }} </small>
          </div>
          <div v-else>
            <p>Are you sure you want to mark this recommendation as completed?</p>
            <i class="fa-duotone fa-check"></i><small> {{ singleRecommendation.name }} </small>
          </div>
        </div>
      </div>
      <template #footer>
        <button class="btn btn-primary" @click="onClickUpdateRecommendationStatus(singleRecommendation.completed)">Confirm</button>
      </template>
    </Modal>
  </div>
</template>
<script>
import SectionTitle from '@/components/base/SectionTitle';
import { debounce } from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import TabTitle from '@/components/base/TabTitle';
import Modal from '@/components/global/SModal';
import FormGroup from '@/components/FormGroup';

export default {
  name: 'AssetRecommendations',
  components: {
    SectionTitle,
    Modal,
    FormGroup,
    TabTitle
  },
  data() {
    return {
      categories: [
        { label: 'Air Quality', value: 'air' },
        { label: 'Biodiversity', value: 'bio' },
        { label: 'Energy', value: 'energy' },
        { label: 'Health and Wellbeing', value: 'health' },
        { label: 'Other', value: 'other' },
        { label: 'Transport', value: 'transport' },
        { label: 'Waste', value: 'waste' },
        { label: 'Water', value: 'water' }
      ],
      form: {
        _id: '',
        icon: '',
        category: '',
        name: null,
        description: '',
        assetId: ''
      },
      singleRecommendation: {},
      selectedRecommendation: {},
      globalFlag: false,
      edit: false,
      modals: {
        modify: false,
        statusUpdate: false
      },
      filters: {
        search: '',
        status: '',
        category: ''
      },
      loading: false
    };
  },
  async created() {
    this.loading = true;
    await this.listRecommendations({ id: this.$route.params.id });
    this.loading = false;
  },
  methods: {
    ...mapActions({
      listRecommendations: 'recommendation/getRecommendationList',
      updateGlobalRecommendation: 'recommendation/updateGlobalRecommendation',
      removeGlobalRecommendation: 'recommendation/removeGlobalRecommendation',
      createRecommendation: 'recommendation/create',
      updateRecommendation: 'recommendation/update',
      removeRecommendation: 'recommendation/remove',
      modifyRecommendationConfirm: 'asset/modifyRecommendation',
      deleteRecommendationConfirm: 'asset/deleteRecommendation'
    }),
    ...mapMutations({
      setValidationErrors: 'asset/SET_VALIDATION_ERRORS'
    }),
    updateRecommendationModal(rec, status) {
      this.modals.statusUpdate = true;
      this.singleRecommendation = rec;
      this.selectedRecommendation = {
        ...rec,
        completed: status
      };
    },
    onSearch: debounce(function () {}, 300),
    getCategoryCount(category) {
      return this.allRecommendations.length > 0 ? this.allRecommendations.filter(rec => rec.category === category).length : 0;
    },
    onClickDocumentUpload() {
      console.log('onClickDocumentUpload triggered');
    },
    onRecommendationModal(global, action, recommendation) {
      if (recommendation) {
        this.edit = true;
        this.form = recommendation;
      }
      this.modals[action] = true;
      this.globalFlag = global;
    },
    async onClickUpdateRecommendationStatus(removeConfirmation) {
      if (removeConfirmation) {
        await this.deleteRecommendationConfirm({ id: this.$route.params.id, recommendationId: this.selectedRecommendation._id });
      } else {
        await this.modifyRecommendationConfirm({ id: this.$route.params.id, data: this.selectedRecommendation });
      }

      this.resetModals('statusUpdate');
    },
    resetModals(action) {
      this.singleRecommendation = {};
      this.modals[action] = false;
      this.edit = false;
      this.form = {
        _id: '',
        icon: '',
        category: '',
        name: null,
        description: '',
        assetId: ''
      };
    },
    onModalClose(action) {
      this.resetModals(action);
    },
    async onDeleteRecommendation() {
      this.globalFlag ? await this.removeGlobalRecommendation({ id: this.form._id }) : await this.removeRecommendation({ id: this.form._id });
      this.resetModals('modify');
    },
    async onClickRecommendation(action) {
      try {
        const editedForm = {
          ...this.form,
          assetId: this.globalFlag ? null : this.$route.params.id,
          companyId: this.globalFlag ? null : this.asset.companyId
        };
        if (this.edit) {
          this.globalFlag
            ? await this.updateGlobalRecommendation({ id: this.form._id, data: { ...editedForm } })
            : await this.updateRecommendation({ id: this.form._id, data: { ...editedForm } });
        } else {
          await this.createRecommendation({ data: editedForm });
        }

        if (Object.keys(this.validationErrors).length > 0) {
          window.scroll(0, 0);
          return this.$toasted.error(`Could not ${this.edit ? 'modify' : 'create'} recommendation.`, { position: 'bottom-right', duration: 3000 });
        } else {
          this.$toasted.success('Recommendation created successfully', { position: 'bottom-right', duration: 3000 });
          this.setValidationErrors({});
          this.resetModals(action);
        }
      } catch (e) {
        this.resetModals(action);
        if (this.$auth.isAdmin) {
          console.log(e);
        }
        this.$toasted.error(`Could not ${this.edit ? 'modify' : 'create'} recommendation.`);
      }
    }
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      validationErrors: 'recommendation/validationErrors',
      global: 'recommendation/global',
      userRecommendations: 'recommendation/recommendations',
      recommendation: 'recommendation/recommendation'
    }),
    allRecommendations() {
      if (!this.asset) return [];

      const res = this.global
        ? [...this.global, ...this.userRecommendations.filter(p => p.assetId === this.$route.params.id)].map(p => ({
            ...p,
            completed: this.asset.recommendations.find(rec => rec.recommendationId === p._id)
          }))
        : [];
      return res.filter(p => !p.deleted);
    },
    recommendations() {
      const res = this.allRecommendations;
      let filteredRes = res;

      if (this.filters.status === 'completed') {
        filteredRes = res.filter(p => p.completed);
      } else if (this.filters.status === 'pending') {
        filteredRes = res.filter(p => !p.completed);
      }

      if (this.filters.search) {
        filteredRes = filteredRes.filter(
          p =>
            p.name.toLowerCase().includes(this.filters.search.toLowerCase()) ||
            p.description.toLowerCase().includes(this.filters.search.toLowerCase())
        );
      }

      if (this.filters.category) {
        filteredRes = filteredRes.filter(p => p.category === this.filters.category);
      }

      return filteredRes;
    },
    completedRecommendations() {
      return this.allRecommendations.length > 0 ? this.allRecommendations.filter(p => p.completed).length : 0;
    },
    pendingRecommendations() {
      return this.allRecommendations.length > 0 ? this.allRecommendations.filter(p => !p.completed).length : 0;
    }
  }
};
</script>

<style scoped>
.details-button span {
  max-width: 0;
  display: inline-flex;
  white-space: nowrap;
  transition: max-width 0.5s, padding-right 0.45s; /* Transition width and padding to avoid "popping" */
  overflow: hidden;
}
.details-button:hover span,
.details-button:focus span {
  transition-delay: 0.1s;
  max-width: 100px; /* how far the text can expand, adjust based on content */
  padding-right: 10px !important; /* Adds spacing on the right of the text when expanded */
}
</style>
